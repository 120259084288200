module.exports = {
  colors: {
    primary: "#76260C",
    secondary: "#76260C",
    tertiary: "#76260C",
    text: "#76260C",
    background: "#FE4B11",
  },
  breakpoints: {
    xs: "480px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  fonts: {
    primary: 'Neue EO Display 65',
  },
  borderRadius: "1rem",
  pageWidth: "100%",
}
